import BaseRequest from './base_request';

class ReportRequest extends BaseRequest {

    settleForCustomers(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'settleForCustomers', filter);
    }

    downloadSettleForCustomers(filter) {
        return this.performDownload('downloadSettleForCustomers', filter);
    }

    hasPaidDeliveryReport(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'hasPaidDeliveryReport', filter);
    }

    hasPaidDeliveryReportAllData(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'hasPaidDeliveryReportAllData', filter);
    }

    downloadHasPaidDeliveryReport(filter) {
        return this.performDownload('downloadHasPaidDeliveryReport', filter);
    }

    getDriversSummaryReport(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getDriversSummaryReport', filter);
    }

    downloadDriversSummaryReport(filter) {
        return this.performDownload('downloadDriversSummaryReport', filter);
    }

    downloadDriversSalary(filter) {
        return this.performDownload('downloadDriversSalary', filter);
    }

    getCustomerSettlement(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getCustomerSettlement', filter);
    }
    
    getDriversSummaryTotal(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getDriversSummaryTotal', filter);
    }

    getTotalCustomerSettlement(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getTotalCustomerSettlement', filter);
    }

    getTotalPendingFee(filter) {
        return this.performRequest(BaseRequest.METHOD_GET, 'getTotalPendingFee', filter);
    }
}

export default new ReportRequest('reports');