<template>
    <base-index
        title="Customer Invoice"
        :entities="entities"
        :columns="columns"
        @onPageChanged="onPageChanged"
        :config="config">

        <template #controls>
            <base-button
                @click="downloadInvoice"
                size="sm"
                type="neutral"><i class="fa fa-download"></i>Download</base-button>
        </template>

        <template #filters>
            <b-col sm="3" v-if="$store.getters['auth/isSuper']">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Branch <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getBranches"
                                placeholder="Pick Branch"
                                v-model="filter.branch_id"
                            >
                                <el-option v-for="option in branches"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Operation <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Pick Operation"
                                v-model="filter.operation_id"
                            >
                                <el-option v-for="option in operations"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="`${ option.name } (${ option.branch.name })`"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Customer <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getReports"
                                :remote-method="getCustomers"
                                placeholder="Search Customer By Name Or Phone"
                                v-model="filter.customer_id"
                            >
                                <el-option v-for="option in customers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <label class="form-control-label"> Date</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                    <flat-picker
                            @change="getReports"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="filter.date">
                    </flat-picker>
                </base-input>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Status <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Status"
                                v-model="filter.has_paid_delivery_fee"
                            >
                                <el-option v-for="option in hasPaidOptions"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="`${ option.value }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

        <template #summary>
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Total ($)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Total (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Total CPF ($)</label>
                        <div class="has-label">
                            <el-input :value="totalPendingUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Total CPF (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalPendingRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Grand Total ($)</label>
                        <div class="has-label">
                            <el-input :value="grandTotalUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="2">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Grand Total (៛)</label>
                        <div class="has-label">
                            <el-input :value="grandTotalRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

        <template #cell_total_usd="{ entity }">
            {{ entity.total_usd > 0 ? entity.total_usd : 0 }}
        </template>

        <template #cell_total_riel="{ entity }">
            {{ entity.total_riel > 0 ? entity.total_riel : 0 }}
        </template>

        <template #cell_has_settled="{ entity }">
            <badge :type="entity.has_settled ? 'success' : 'danger'">{{ entity.has_settled ? 'Paid' : 'Not Paid' }}</badge>
        </template>

        <template #cell_pending_riel="{ entity }">
            <base-input
                type="number"
                v-model="entity.pending_riel"
                name="pending_riel"
                @blur="onPendingBlur({
                    customer_id: entity.id, 
                    pending_riel: entity.pending_riel,
                    pending_usd: entity.pending_usd,
                    date: filter.date
                })">
            </base-input>
        </template>
        <template #cell_pending_usd="{ entity }">
            <base-input
                type="number"
                v-model="entity.pending_usd"
                name="pending_usd"
                @blur="onPendingBlur({
                    customer_id: entity.id, 
                    pending_riel: entity.pending_riel,
                    pending_usd: entity.pending_usd,
                    date: filter.date
                })">
            </base-input>
        </template>

        <template #cell_grand_total_riel="{ entity }">
            {{ (entity.total_riel > 0 ? entity.total_riel : 0) - entity.pending_riel }}
        </template>

        <template #cell_grand_total_usd="{ entity }">
            {{ (entity.total_usd > 0 ? entity.total_usd : 0) - entity.pending_usd }}
        </template>

        <template #actions="{ entity }">
            <el-tooltip placement="top" :content="`${ entity.has_settled ? 'Unpaid' : 'Paid'}`">
                <base-button @click="settle(entity)" size="sm" :type="entity.has_settled ? 'warning' : 'primary'"><i class="fa fa-dollar-sign"></i></base-button>
            </el-tooltip>
            <el-tooltip placement="top" content="Preview">
                <base-button @click="downloadCustomerInvoice(entity.id, {preview: true})" size="sm" type="success"><i class="fa fa-eye"></i></base-button>
            </el-tooltip>
            <el-tooltip placement="top" content="Download Invoice">
                <base-button @click="downloadCustomerInvoice(entity.id, {preview: false})" size="sm" type="info"><i class="fa fa-download"></i></base-button>
            </el-tooltip>
        </template>

        <template #others>
            <modal-input-data @downloadCustomerInvoice="downloadCustomerInvoice" ref="modalInputData"></modal-input-data>
        </template>

    </base-index>
</template>
<script>
import BaseIndex from '@components/Custom/BaseIndex';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    Select,
    Option,
} from 'element-ui'
import store from './store';
export default {
    data() {
        return  {
            filter: {
                operation_id: '',
                customer_id: '',
                date: new Date(),
                page: 1,
                meta: {},
                has_paid_delivery_fee: null
            },
            columns: [
                {
                    label: 'Code',
                    key: 'code',
                },
                {
                    label: 'Total ($)',
                    key: 'total_usd',
                    formatAs: 'currency'
                },
                {
                    label: 'Total (៛)',
                    key: 'total_riel',
                    formatAs: 'currency'
                },
                {
                    label: 'CPF (៛)',
                    key: 'pending_riel',
                    formatAs: 'currency'
                },
                {
                    label: 'CPF ($)',
                    key: 'pending_usd',
                    formatAs: 'currency'
                },
                {
                    label: 'Grand Total ($)',
                    key: 'grand_total_usd',
                    formatAs: 'currency'
                },
                {
                    label: 'Grand Total (៛)',
                    key: 'grand_total_riel',
                    formatAs: 'currency'
                },
                {
                    label: 'Paid Status',
                    key: 'has_settled'
                }
            ],
            config: {
                showAddNew: false,
                hasBasicActions: false
            },
            selectedEntity: null,
            hasPaidOptions: [
                {
                    label: 'Paid',
                    value: true
                },
                {
                    label: 'Not Paid',
                    value: false
                }
            ],
        };
    },
    methods: {
        getMeta(entity, preview = false) {
            this.selectedEntity = entity;
            this.$refs.modalInputData.show({
                preview
            });
        },
        downloadCustomerInvoice(customerId, meta) {
            this.filter.meta = meta;
            store.dispatch('downloadCustomerInvoice', {
                customerId,
                preview: meta.preview,
                filter: this.filter
            })
            this.$refs.modalInputData.hide();
        },
        downloadInvoice() {
            store.dispatch('downloadInvoice', {
                date: this.filter.date
            });
        },
        download() {
            store.dispatch('download', this.filter);
        },
        settle(entity) {
            this.popupConfirm('Are you sure?', null, () => {
                store.dispatch('settle', {
                    entity,
                    filter: {
                        ...this.filter,
                        has_paid_delivery_fee: !entity.has_settled
                    }
                })
                .then(() => {
                    this.toastSuccess('Mark as completed');
                    this.getReports();
                });
            });
        },
        getCustomers(search) {
            store.dispatch('getCustomers', {
                search
            });
        },
        getReports() {
            store.dispatch('index', this.filter);
            store.dispatch('getTotalCustomerSettlement', this.filter);
        },
        getBranches() {
            store.dispatch('getBranches')
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getReports();
        },
        onPendingBlur(data) {
            store.dispatch('setPendingFee', data).then(() => {
                this.toastSuccess('Mark as completed');
                this.getReports();
            }).catch(err => {
                this.toastSuccess('Technical Error');
            });
            
            
        }
    },
    mounted() {
        this.getReports();
        this.getBranches();
        store.dispatch('getOperations');
    },
    computed: {
        entities() {
            return store.getters['entities'];
        },
        customers() {
            return store.getters['customers'];
        },
        operations() {
            return store.getters['operations'].filter(operation => {
                return this.filter.branch_id ? operation.branch_id === this.filter.branch_id : true;
            });
        },
        branches() {
            return store.getters['branches'];
        },
        totalReports() {
            return store.getters['totalReports'];
        },
        totalPriceUsd() {
            return this.totalReports.total_usd !== undefined ? this.totalReports.total_usd > 0 ? new Intl.NumberFormat().format(this.totalReports.total_usd.toFixed(2)) : 0 : 0;
        },
        totalPriceRiel() {
            return this.totalReports.total_riel !== undefined ? this.totalReports.total_riel > 0 ? new Intl.NumberFormat().format(this.totalReports.total_riel.toFixed(2)) : 0 : 0;
        },
        totalPendingRiel() {
            return this.totalReports.total_pending_riel !== undefined ? this.totalReports.total_pending_riel > 0 ? new Intl.NumberFormat().format(this.totalReports.total_pending_riel.toFixed(2)) : 0 : 0;
        },
        totalPendingUsd() {
            return this.totalReports.total_pending_usd !== undefined ? this.totalReports.total_pending_usd > 0 ? new Intl.NumberFormat().format(this.totalReports.total_pending_usd.toFixed(2)) : 0 : 0;
        },
        grandTotalRiel() {
            return new Intl.NumberFormat().format((this.totalReports.total_riel - this.totalReports.total_pending_riel).toFixed(2))
        },
        grandTotalUsd() {
            return new Intl.NumberFormat().format((this.totalReports.total_usd - this.totalReports.total_pending_usd).toFixed(2))
        }
    },
    watch: {
        'filter.date': function(val) {
            if (val) {
                this.getReports();
            }
        }
    },
    components: {
        BaseIndex,
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker,
        ModalInputData: () => import('./ModalInputData')
    }
}
</script>