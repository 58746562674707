import BaseRequest from './base_request';

class InvoicePendingFeeRequest extends BaseRequest {

    setPendingFee(data) {
        return this.performRequest(BaseRequest.METHOD_POST, '', data);
    }

}

export default new InvoicePendingFeeRequest('invoicePendingFee');